/**
 * 格式化手机号 将13671587169 格式化成 136 7158 7169
 * @param val 手机号码
 */
export const formatPhone = (val: string | number): string => {
  val = val + "";
  return `${val.slice(0, 3)} ${val.slice(3, 7)} ${val.slice(7)}`;
};

/**
 * 获取URL参数
 * @param variable 参数名称
 */
export const getQueryVariable = (variable: string): any => {
  const query: any = window.location.href.split("?")[1];
  if (!query) return;
  let vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
};

// 自动添加协议
export function autoProtocol(url: string) {
  if (url.indexOf("https://") === 0 || url.indexOf("http://") === 0 || url.indexOf("//") === 0) {
    return url;
  }
  if (url.indexOf("://") > 0) {
    return url;
  }
  return "//" + url;
}

/**
 * 判断是否为空
 * @param value
 */
export const isEmpty = (value: string) => {
  if (value === null || value === "" || value === undefined || value === "null") {
    return true;
  } else {
    value = value.replace(/\s/g, "");
    if (value === "") {
      return true;
    }
    return false;
  }
};

/**
 * 设置storage
 * @param value
 */
export const setStorage = (key: string, value: string) => {
  sessionStorage.setItem(key, value);
};

/**
 * 获取storage
 * @param value
 */
export const getStorage = (key: string) => {
  return sessionStorage.getItem(key);
};

/**
 * 移除storage
 * @param value
 */
export const clearStorage = (key: string) => {
  sessionStorage.removeItem(key);
};

export const isInWeixin = () => {
  let user: any = navigator.userAgent.toLowerCase();
  if (user.match(/MicroMessenger/i) == "micromessenger") {
    return true;
  } else {
    return false;
  }
};

/** 是否企业微信环境 */
export function isWeCom() {
  let ua: any = navigator.userAgent.toLowerCase();
  return ua.match(/wxwork/i) == "wxwork";
}

/** 是否个微环境 */
export function isWeChat() {
  let ua: any = navigator.userAgent.toLowerCase();
  return !isWeCom() && ua.match(/micromessenger/i) == "micromessenger";
}

/**
 * 判断是否为手机号
 * @param mobile 手机号码
 */
export const isMobile = (mobile: any) => {
  let myreg = /^1[3-9]\d{9}$/;
  if (!myreg.test(mobile)) {
    return false;
  } else {
    return true;
  }
};

/**
 * 智能表单字段校验: 未通过验证为true
 */
export const isFormFieldVer = (name: string, data: any) => {
  if (name == "mobile") {
    return !/^1[3-9]\d{9}$/.test(data);
  }
  if (name == "email") {
    return !/^(\w+([-.][A-Za-z0-9]+)*){3,18}@\w+([-.][A-Za-z0-9]+)*\.\w+([-.][A-Za-z0-9]+)*$/.test(
      data
    );
  }
  if (name == "qq") {
    return !/^[1-9][0-9]{4,14}$/.test(data);
  }
};

/**
 * 判断是否为PC端: true为是
 */
export const isPC = () => {
  if (
    /Android|webOS|iPhone|iPod|BlackBerry|SymbianOS|iPad|Windows Phone/i.test(navigator.userAgent)
  ) {
    return false;
  } else {
    return true;
  }
};

/**
 * 判断是否为PC端且windows系统打开的微信内置浏览器
 */
export const isPCWeChat = () => {
  if (/MicroMessenger/i.test(window.navigator.userAgent)) {
    const system = {
      win: -1,
      mac: -1
    };
    //检测平台
    const p = navigator.platform;
    system.win = p.indexOf("Win");
    if (system.win != -1) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};

/**
 * 获取cookie
 * @param name cookie名字
 * @returns 值
 */
export const getCookie = (name: string) => {
  let cookies = document.cookie.split("; ");
  for (let cookie of cookies) {
    let arr = cookie.split("="); // 解析出名和值
    if (arr[0] == name) return decodeURIComponent(arr[1]); // 对cookie值解码
  }
  return null;
};
/**
 * 删除cookie
 * @param name cookie名字
 * @returns 值
 */
export const deleteCookie = (name: string) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
};
