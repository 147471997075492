import { isPC, isWeChat, isWeCom } from "@/utils/utils";
import Vconsole from "vconsole";


const $runtime = {
    isWeChat: isWeChat(),   //个微
    isWeCom: isWeCom(),     //企微
    isPC: isPC()
}

if(['uat','dev','dev.local','uat.local','stg','stg.local'].includes(process.env.NODE_ENV as string)){
    new Vconsole();
}
// 开发时调整
// 设置微信环境及cookie
if(process.env.NODE_ENV === 'dev.local' && process.env.VUE_APP_RUNTIME_STR){
    eval(process.env.VUE_APP_RUNTIME_STR)
}

export default $runtime