import Vue from "vue";
import {
  Field,
  Radio,
  RadioGroup,
  // Popup,
  Button,
  Loading,
  Notify,
  Popup,
  // ActionSheet,
  Cell,
  CellGroup,
  // NoticeBar,
  // Uploader,
  // Empty,
  // Tabs,
  // Tab,
  // Sticky,
  // NavBar,
  // Tabbar,
  // TabbarItem,
  // Search,
  // Popover,
  // Collapse,
  // CollapseItem,
  CheckboxGroup,
  Checkbox,
  Empty,
  // PullRefresh,
  // List,
  // Tag,
  // Swipe,
  // SwipeItem,
  // Overlay,
  // Circle,
  Picker,
  DatetimePicker,
  // Calendar,
  Form,
  Row,
  Col
} from "vant";

Vue.use(Field);
Vue.use(Radio);
Vue.use(RadioGroup);
// Vue.use(Popup);
Vue.use(Loading);
Vue.use(Notify);
Vue.use(Popup);
Vue.use(Button);
Vue.use(Cell);
Vue.use(CellGroup);
// Vue.use(NoticeBar);
// Vue.use(Uploader);
// Vue.use(Empty);
// Vue.use(Tabs);
// Vue.use(Tab);
// Vue.use(Sticky);
// Vue.use(NavBar);
// Vue.use(Tabbar);
// Vue.use(TabbarItem);
// Vue.use(Search);
// Vue.use(Popover);
// Vue.use(Collapse);
// Vue.use(CollapseItem);
Vue.use(CheckboxGroup);
Vue.use(Checkbox);
Vue.use(Empty);
// Vue.use(PullRefresh);
// Vue.use(List);
// Vue.use(Tag);
// Vue.use(ActionSheet);
// Vue.use(Swipe);
// Vue.use(SwipeItem);
// Vue.use(Overlay);
// Vue.use(Circle);
Vue.use(Picker);
Vue.use(DatetimePicker);
// Vue.use(Calendar);
Vue.use(Form);
Vue.use(Row);
Vue.use(Col);
