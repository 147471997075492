import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import "@/utils/vant";
import "@/assets/scss/reset.scss";
import "@/assets/scss/output.scss";
import "@/assets/scss/com.css";
import VueCompositionAPI from "@vue/composition-api";
import $runtime from "@/hooks/init"

Vue.use(VueCompositionAPI);
Vue.config.productionTip = false;

new Vue({
  router,
  provide () {
    return {
      $runtime: $runtime
    }
  },
  render: h => h(App)
}).$mount("#app");
