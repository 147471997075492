import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/Home.vue"),
    meta: {
      title: "卫瓴·协同CRM"
    }
  },
  {
    path: "/weiling-with-button",
    name: "WeilingWithButton",
    component: () => import(/* webpackChunkName: "about" */ "../views/WeilingWithButton.vue"),
    meta: {
      title: "卫瓴·协同CRM"
    }
  },
  {
    path: "/success",
    name: "Success",
    component: () => import(/* webpackChunkName: "about" */ "../views/Success.vue"),
    meta: {
      title: "卫瓴·协同CRM"
    }
  },
  {
    path: "/success-wx-im",
    name: "SuccessWxIm",
    component: () => import(/* webpackChunkName: "about" */ "../views/SuccessWxIm.vue"),
    meta: {
      title: "卫瓴·协同CRM"
    }
  },
  {
    path: "/form-success",
    name: "FormSuccess",
    component: () => import(/* webpackChunkName: "about" */ "../views/FormSuccess.vue"),
    meta: {
      title: " "
    }
  },
  {
    path: "/paid-success",
    name: "PaidSuccess",
    component: () => import(/* webpackChunkName: "about" */ "../views/PaidSuccess.vue"),
    meta: {
      title: " "
    }
  },
  {
    path: "/one-click-jump-page",
    name: "OneClickJumpPage",
    component: () => import(/* webpackChunkName: "about" */ "../views/OneClickJumpPage.vue"),
    meta: {
      title: " "
    }
  },
  {
    path: "/link-jump-page",
    name: "LinkJumpPage",
    component: () => import(/* webpackChunkName: "about" */ "../views/LinkJumpPage.vue"),
    meta: {
      title: " "
    }
  },
  {
    path: "/polyv-jump-page",
    name: "PolyvJumpPage",
    component: () => import(/* webpackChunkName: "polyv" */ "../views/PolyvJumpPage.vue"),
    meta: {
      title: " "
    }
  },
  {
    path: "/xet-jump-page",
    name: "XetJumpPage",
    component: () => import(/* webpackChunkName: "live" */ "../views/XetJumpPage.vue"),
    meta: {
      title: " "
    }
  },
  {
    path: "/ad-page-with-form",
    name: "AdPageWithForm",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/willingADpages/ADpageWithWxForm.vue"),
    meta: {
      title: "卫瓴·协同CRM"
    }
  },
  {
    path: "/yimdx",
    name: "AdPageWithFormOne",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/willingADpages/ADpageWithWxForm1.vue"),
    meta: {
      title: "卫瓴·协同CRM"
    }
  },
  {
    path: "/erlxz",
    name: "AdPageWithFormTwo",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/willingADpages/ADpageWithWxForm2.vue"),
    meta: {
      title: "卫瓴·协同CRM"
    }
  },
  {
    path: "/sanykt",
    name: "AdPageWithFormThree",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/willingADpages/ADpageWithWxForm3.vue"),
    meta: {
      title: "卫瓴·协同CRM"
    }
  },
  {
    path: "/willing-sign-up",
    name: "WillingSignUp",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/willingSignUpCheckIn/SignUp.vue"),
    meta: {
      title: ""
    }
  },
  {
    path: "/willing-sign-up-success",
    name: "WillingSignUpSuccess",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/willingSignUpCheckIn/SignUpSuccess.vue"),
    meta: {
      title: ""
    }
  },
  {
    path: "/willing-check-in",
    name: "WillingCheckIn",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/willingSignUpCheckIn/CheckIn.vue"),
    meta: {
      title: ""
    }
  },
  {
    path: "/test-message-comp",
    name: "TestMessageComp",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/willingTestPages/TestMessageComp.vue"),
    meta: {
      title: " "
    }
  },
  {
    path: "/test-register-comp",
    name: "TestRegisterComp",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/willingTestPages/TestRegisterComp.vue"),
    meta: {
      title: " "
    }
  },
  {
    path: "/worktable-erp-preview-page",
    name: "WorktableErpPreviewPage",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/willingDemoPages/worktableErpPreviewPage.vue"
      ),
    meta: {
      title: " "
    }
  },
  {
    path: "/bus-mtg-sign-up",
    name: "BusinessMeetingSignUp",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/businessMeeting/SignUp/index.vue"),
    meta: {
      title: ""
    }
  },
  {
    path: "/bus-mtg-sign-up-success",
    name: "BusinessMeetingSignUpSuccess",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/businessMeeting/SignUpSuccess/index.vue"),
    meta: {
      title: ""
    }
  },
  {
    path: "/bus-mtg-check-in",
    name: "BusinessMeetingCheckIn",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/businessMeeting/CheckIn/index.vue"),
    meta: {
      title: ""
    }
  },
  {
    path: "/smart-form-sign-up",
    name: "SmartFormSignUp",
    component: () => import(/* webpackChunkName: "about" */ "../views/smartForm/SignUp/index"),
    meta: {
      title: ""
    }
  },
  {
    path: "/smart-form-sign-up-success",
    name: "SmartFormSignUpSuccess",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/smartForm/SignUpSuccess/index.vue"),
    meta: {
      title: ""
    }
  },
  {
    path: "/willing-blank-redirect-page",
    name: "BlankRedirectPage",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/willingBlankRedirectPage/index.vue"),
    meta: {
      title: ""
    }
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
